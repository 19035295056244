<template>
  <div>
    <PageHeader fileName="header-21" withTablet withMobile>
      <h3>{{ $lang("blog.title", true) }}</h3>
    </PageHeader>

    <section class="py-7">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-8">
            <div class="blog-post">
              <h4 class="blog-post-title">
                Enjoy the wonders of Spain and make friends for life in Madrid
                and Barcelona!
              </h4>

              <div class="links-container">
                <p class="date">25/03/2023</p>
                <!-- <ul class="link-list">
                  <li v-for="(tag, ti) of post.tags" :key="ti">
                    <a class="text-primary" href="#">{{ tag }}</a>
                  </li>
                </ul> -->
                <ul class="link-list">
                  <li>
                    <a class="text-primary" href="#">Life in Spain</a>
                  </li>
                  <li>
                    <a class="text-primary" href="#">Barcelona</a>
                  </li>
                  <li>
                    <a class="text-primary" href="#">Madrid</a>
                  </li>
                </ul>
              </div>

              <div class="blog-post-img">
                <!-- <div
                  class="img-container img-container-rounded"
                  :style="`background-image: url('${post.image_url}')`"
                /> -->
                <div
                  class="img-container img-container-rounded"
                  :style="`background-image: url('/web-v2/img/accommodation.png')`"
                />
              </div>

              <div class="blog-post-body">
                <h6>
                  Living and studying in Spain is an unparalleled adventure that
                  promises not just academic growth but also personal
                  transformation. With its vibrant culture, rich history, and an
                  array of opportunities waiting to be seized, Spain is a
                  magnetic draw for students from all corners of the globe. In
                  this article, we'll dive deeper into the allure of two of
                  Spain's most iconic cities – Madrid and Barcelona – and
                  explore how students can truly immerse themselves in the
                  Spanish way of life while forging friendships that will last a
                  lifetime.
                </h6>
                <p>
                  Explore Spain and the world of language learning through our
                  eyes! In this blog, you'll delve into a wide range of topics,
                  including living in Madrid and Barcelona, tips for learning
                  Spanish and English, guidance on starting your journey to move
                  to Spain, and, not to be overlooked, updates and essential
                  information about our school and campuses.
                </p>
                <p>
                  When it comes to Spain, two cities consistently stand out for
                  their unique charm, culture, and lifestyle - Madrid and
                  Barcelona. These vibrant metropolises have captured the hearts
                  of travelers and language enthusiasts alike, offering an
                  experience that's both enriching and unforgettable.
                </p>

                <p><b>Madrid: Where Tradition Meets Modernity</b></p>

                <p>
                  The Spanish capital, Madrid, is a city that harmoniously
                  blends its storied past with a contemporary urban landscape.
                  Stepping into Madrid is like walking through a living history
                  book. The grandeur of the Royal Palace, the artistic treasures
                  at the Prado Museum, and the bustling energy of Puerta del Sol
                  all evoke a sense of timeless elegance.
                </p>
                <p>
                  But Madrid is more than just its landmarks. It's a city that
                  thrives on its people, culture, and diversity. Students who
                  choose to study here will find themselves immersed in a lively
                  atmosphere where history collides with modernity. Whether
                  you're savoring authentic Spanish cuisine at a local tapas
                  bar, exploring the hidden gems of the Lavapiés neighborhood,
                  or catching a spirited Real Madrid football match at Santiago
                  Bernabéu Stadium, Madrid invites you to participate in its
                  vibrant tapestry.
                </p>
              </div>
            </div>
            <div class="text-center py-6">
              <a href="#" class="blog-post-cta">
                <span class="material-symbols-outlined arrow_right_alt">
                  arrow_left_alt
                </span>

                Back to blog
              </a>
            </div>

            <div class="form-row gap-y-4">
              <div class="col-md-4">
                <div class="blog-post-related">
                  <div
                    class="img-container img-container-rounded"
                    :style="`background-image: url('/web-v2/img/accommodation.png')`"
                  />
                  <div class="blog-post-related-body">
                    <h5>This will be the title of the article</h5>

                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Iste, dolorem eius? Blanditiis ad maxime beatae, optio
                      distinctio est quidem harum dolore tempore. Reiciendis
                      inventore similique aspernatur, consequatur perspiciatis
                      natus corrupti?
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="blog-post-related">
                  <div
                    class="img-container img-container-rounded"
                    :style="`background-image: url('/web-v2/img/accommodation.png')`"
                  />
                  <div class="blog-post-related-body">
                    <h5>This will be the title of the article</h5>

                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Iste, dolorem eius? Blanditiis ad maxime beatae, optio
                      distinctio est quidem harum dolore tempore. Reiciendis
                      inventore similique aspernatur, consequatur perspiciatis
                      natus corrupti?
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="blog-post-related">
                  <div
                    class="img-container img-container-rounded"
                    :style="`background-image: url('/web-v2/img/accommodation.png')`"
                  />
                  <div class="blog-post-related-body">
                    <h5>This will be the title of the article</h5>

                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Iste, dolorem eius? Blanditiis ad maxime beatae, optio
                      distinctio est quidem harum dolore tempore. Reiciendis
                      inventore similique aspernatur, consequatur perspiciatis
                      natus corrupti?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection fileName="footer-18" theme="secondary">
      {{ $lang("blog.contact_form.location", true) }}
    </ContactFormSection>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";

export default {
  components: {
    PageHeader,
    ContactFormSection,
  },
  data: () => ({}),
};
</script>

<style></style>
